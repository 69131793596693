<template>
    <q-page padding>
      <CampaignsApiGenerator />
    </q-page>
  </template>

  <script>
  import CampaignsApiGenerator from '@/components/api/CampaignsApiGenerator.vue';
  export default {
    name: 'CampaignsApi',
    components: { CampaignsApiGenerator }
  };
  </script>

  <style></style>
