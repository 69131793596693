<template>
  <q-card flat class="justify-center creative">
    <q-card-section>
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('campaigns_api') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
        <q-breadcrumbs-el :label="$t('campaigns_api')" exact />
      </q-breadcrumbs>
    </q-card-section>
    <q-card-section>
      <q-banner class="bg-info text-white q-mb-md" rounded>
        <q-item>
          <q-item-section top avatar>
            <q-icon name="mdi-information-outline" size="lg" />
          </q-item-section>
          <q-item-section>
            <q-item-label caption lines="3" class="text-white">
              Clique
              <a
                href="https://drive.google.com/file/d/1WBZPKidGY1EtjnJE0fIeg1Uk48_Y56gs/view"
                style="text-decoration: none"
                class="q-mt-md"
                target="_blank"
                >aqui</a
              >
              para saber mais.
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-banner>
    </q-card-section>
    <q-separator spaced />
    <q-card-section class="row q-col-gutter-md">
      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.id" :label="$t('affiliate_id')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.id)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar ID </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.token" label="Token">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar Token
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-12">
        <q-input outlined readonly type="text" :value="getApiLink" :label="$t('campaigns_api_url')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLink)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar API
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
const dataLink = process.env.VUE_APP_DATA_API;

import affiliateService from '../../services/AffiliateService';
import AffiliateMixin from '../../mixins/AffiliateMixin';
import AdvertiserService from '@/services/AdvertiserService';

export default {
  mixins: [affiliateService, AffiliateMixin, AdvertiserService],
  name: 'CampaignsApiGenerator',
  data() {
    return {
      affiliateData: {
        email: null,
        id: null,
        token: null
      },
      campaignOptions: [],
      apiDataUrl: dataLink,
      campaign: null
    };
  },
  computed: {
    getProfiles() {
      return this.$store.state.Account.user;
    },
    getApiLink() {
      if (this.affiliateData.id && this.affiliateData.token) {
        return `${this.apiDataUrl}/campaign?affid=${this.affiliateData.id}&token=${this.affiliateData.token}`;
      }
      return null;
    }
  },

  methods: {
    async validateAndEncodeUrl(value) {
      if (value == '') this.encodedUrl = '';
      try {
        var url = new URL(value);
        if (url.protocol == 'https:') {
          this.encodedUrl = encodeURIComponent(url.toString());
        }
        return;
      } catch (error) {
        return;
      }
    },

    async setupAffiliateData() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(this.getProfiles.affiliate_id);
        if (status === 200) {
          this.affiliateData = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  created() {
    this.setupAffiliateData();
  }
};
</script>

<style></style>
